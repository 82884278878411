<template>
  <base-wrapper :loading="loading">
    <div class="space-y-4">
      <shipment-order-detail-card :order="order" />
      <create-shipment-form
        :order="order"
        :order-id="$route.params.id"
        :loading="loadingShipment"
        batch-mode="scan"
        @update-loading="(val) => (loadingShipment = val)"
        @submitted="$router.push(indexPath)"
      >
        <template #action>
          <base-button color="white" :to="indexPath">
            <Icon icon="heroicons:arrow-uturn-left" class="h-4 w-4" />
            Kembali
          </base-button>
        </template>
      </create-shipment-form>
    </div>
  </base-wrapper>
</template>
<script>
import ShipmentOrderDetailCard from '@/components/shipment/shipment-order-detail-card.vue';
import CreateShipmentForm from '@/components/shipment/create-shipment-form.vue';
import { requestMixin } from '@/mixins/request/request';

export default {
  mixins: [requestMixin],
  components: {
    ShipmentOrderDetailCard,
    CreateShipmentForm,
  },
  data() {
    return {
      indexPath: {
        name: 'gudang.susulan.barang-ke-stockist'
      },
      loadingOrder: false,
      loadingShipment: false,
      order: {
        data: {
          attributes: {},
          relationships: {
            'destination-office': {
              data: null,
            },
            'origin-office': {
              data: null,
            },
            village: {
              data: null,
            },
            district: {
              data: null,
            },
            city: {
              data: null,
            },
            province: {
              data: null,
            },
          },
        },
        included: [],
      },
    };
  },
  computed: {
    loading() {
      return this.loadingOrder || this.loadingShipment;
    },
  },
  methods: {
    async loadOrder() {
      this.loadingOrder = true;

      const [res, err] = await this.request(
        `/api/v1/orders/${this.$route.params.id}`,
        {
          params: {
            include:
              'destination-office,village,district,city,province,origin-warehouse,destination-warehouse,origin-office',
            'fields[orders]':
              'warehouse_status,origin_code,destination_code,orderedAt,createdAt,destination-office,order_shipment,shipment_address,village,district,city,province,origin-warehouse,destination-warehouse,origin-office',
            'fields[offices]': 'code,name,address,address2,phone',
            'fields[villages]': 'name',
            'fields[districts]': 'name',
            'fields[cities]': 'name',
            'fields[provinces]': 'name',
            'fields[warehouses]': 'code',
          },
        }
      );

      if (!err) {
        this.order = res;
      }

      this.loadingOrder = false;
    },
  },
  created() {
    this.loadOrder();
  },
};
</script>
